import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { Router } from '@angular/router';

import { Observable, catchError, map, tap } from 'rxjs';

import { Restaurant } from 'src/app/models/restaurant';
import { environment } from 'src/environments/environment'
import { LoginPayload, SignUpPayload } from 'src/app/models/auth';
import { ErrorHandlerService } from '../error-handler/error-handler.service';
import { ServerResponse } from 'src/app/models/response';
import { Store } from '@ngxs/store';
import { UserState } from 'src/app/@store/user/state';
import { SaveUser } from 'src/app/@store/user/action';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  baseUrl = `${environment.BASE_URL}/auth/restaurant`
  redirectUrl = ''

  constructor(private readonly http: HttpClient, private readonly errorHandler: ErrorHandlerService, private store: Store, private router: Router) { }

  get token(): string {
    return this.isAuthenticated ? this.store.selectSnapshot(UserState.user).token : ''
  }
  get isAuthenticated(): boolean {
    return Boolean(this.store.selectSnapshot(UserState.user))
  }

  isLoggedOut(): boolean {
    return !this.isAuthenticated
  }

  login(data: LoginPayload): Observable<Restaurant> {
    return this.http.post<ServerResponse<Restaurant>>(`${this.baseUrl}/login`, data).pipe(
      map((res) => res.content),
      tap(res => this.store.dispatch(new SaveUser(res))),
      catchError((err) => this.errorHandler.handleHttpError(err, 'Login Error', null))
    )
  }

  logout() {
    this.store.reset({});
    localStorage.clear();
    this.router.navigate(['/', 'auth', 'login']);
    this.redirectUrl = 'dashboard'
  }

  signup(data: SignUpPayload): Observable<Restaurant> {
    return this.http.post<ServerResponse<Restaurant>>(`${this.baseUrl}/signup`, data).pipe(
      map((res) => res.content),
      tap(res => this.store.dispatch(new SaveUser(res))),
      catchError((err) => this.errorHandler.handleHttpError(err, 'Signup Error', null))
    )
  }

}
