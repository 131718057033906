import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { ConfirmationService, MessageService } from 'primeng/api';
import { NgxsModule } from '@ngxs/store';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { UserState } from './@store/user/state';
import { environment } from 'src/environments/environment';
import { MealState } from './@store/meal/state';
import { AuthInterceptor } from './services/auth/auth.interceptor';
import { DialogService } from 'primeng/dynamicdialog';
import { ServiceWorkerModule } from '@angular/service-worker';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule,
    NgxsModule.forRoot([UserState, MealState], {
      developmentMode: !environment.production
    }),
    NgxsStoragePluginModule.forRoot({
      key: [UserState, MealState]
    }),
    NgxsLoggerPluginModule.forRoot({
      // Do not log in production mode
      disabled: environment.production,
    }),
    ServiceWorkerModule.register('ngsw-worker.js', { registrationStrategy: 'registerImmediately', enabled: !isDevMode() })
    // ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [MessageService, DialogService, ConfirmationService, {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true,
  },],
  bootstrap: [AppComponent]
})
export class AppModule { }
