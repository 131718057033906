import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Store } from '@ngxs/store';
import { MessageService } from 'primeng/api';
import { first, of, throwError } from 'rxjs';

import { ClearUser } from 'src/app/@store/user/action';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {

  constructor(private readonly toast: MessageService, private router: Router, private store: Store) { }


  handleHttpError(err: HttpErrorResponse, errorTitle: string, alternativeData: any = null) {
    let errorMessage = '';
    let errorHeader = errorTitle;

    if (err.error instanceof ErrorEvent) {
      errorMessage = `Client-side error: ${err.error.message}`;
    } else {
      const { error } = err;

      console.log(error)

      switch (true) {
        case error.errors?.length > 0:
          errorMessage += error.errors.map((err: any) => err.message).join(', ');
          errorHeader = error.message;
          console.log('got here');

          break;
        case error.code === 401:
          errorMessage = 'Please Login to continue';
          errorHeader = 'Authentication error';
          this.store.dispatch(new ClearUser()).pipe(first()).subscribe(() => {
            this.router.navigateByUrl('/auth/login')
          })
          break;

        case error.error:
          errorMessage = error.message;
          break;

        default:
          break;
      }

    }

    // Display Error
    this.toast.add({ severity: 'error', summary: errorHeader, detail: errorMessage })

    return alternativeData ? of(alternativeData) : throwError(() => new Error(err.error));
  }
}
