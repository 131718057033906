import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ClearMeal, SaveMeals } from './action';

@State<MealStateModel>({
    name: 'Meal',
    defaults: undefined
})
@Injectable()
export class MealState {

    /* Seelctors */
    @Selector()
    static allMeal(state: MealStateModel) {
        return state.allMeals
    };


    /* Actions */
    @Action(SaveMeals)
    feedAnimals({ patchState }: StateContext<MealStateModel>, { meals }: SaveMeals) {
        patchState({ allMeals: meals })
    }

    @Action(ClearMeal)
    clearMeal({ patchState }: StateContext<MealStateModel>) {
        patchState({ allMeals: undefined })
    }
}

interface MealStateModel {
    allMeals: any[]
}