import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShowIfInvalidDirective } from './show-if-invalid.directive';



@NgModule({
  declarations: [
    ShowIfInvalidDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ShowIfInvalidDirective
  ]
})
export class DirectivesModule { }
