export const environment = {
    BASE_URL: 'https://dev-server.3080eats.com/api/v1',
    production: false,
    firebaseConfig: {
        apiKey: "AIzaSyBeFWqUy0bSK9mP5RObAF6AohZigoWwtMk",
        authDomain: "eats-dc8ae.firebaseapp.com",
        projectId: "eats-dc8ae",
        storageBucket: "eats-dc8ae.appspot.com",
        messagingSenderId: "640168707635",
        appId: "1:640168707635:web:26e49ab1b05aa19b870546",
        measurementId: "G-T9BV1CWQEN",
        vapidKey: "BKsCJqmeBt2u5Aqp3AB29hiWzh2QCbMfQf1-vah8KzeQw5dN4vxtOuLLM7nEem0BREsPo8lzS6ZqElsDgrZpW-o"
    }
};
