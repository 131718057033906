import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Restaurant } from 'src/app/models/restaurant';
import { ClearUser, SaveUser } from './action';

@State<UserStateModel>({
    name: 'User',
    defaults: undefined
})
@Injectable()
export class UserState {

    /* Seelctors */
    @Selector()
    static user(state: UserStateModel) {
        return state.user
    };


    /* Actions */
    @Action(SaveUser)
    feedAnimals({ patchState, getState }: StateContext<UserStateModel>, { user }: SaveUser) {
        patchState({ user: { ...getState()?.user, ...user } })
    }

    @Action(ClearUser)
    clearUser({ patchState }: StateContext<UserStateModel>) {
        patchState({ user: undefined })
    }
}

interface UserStateModel {
    user: Restaurant
}