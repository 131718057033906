import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ToastModule } from 'primeng/toast'
import { PasswordModule } from 'primeng/password';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { DialogModule } from 'primeng/dialog';
import { MultiSelectModule } from 'primeng/multiselect';

import { DirectivesModule } from '../directives/directives.module';
import { OrderColorPipe } from './pipes/order-color/order-color.pipe';
import { OrderStatusDisplayPipe } from './pipes/order-status-display/order-status-display.pipe';
import { CardLoadingComponent } from './components/card-loading/card-loading.component';
import { AmountComponent } from './components/amount/amount.component';
import { StartEntryPipe } from './pipes/start-entry/start-entry.pipe';
import { EndEntryPipe } from './pipes/end-entry/end-entry.pipe';
import { PaginationComponent } from './components/pagination/pagination.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { KoboToNairaPipe } from './pipes/kobo-to-naira/kobo-to-naira.pipe';
import { NairaToKoboPipe } from './pipes/naira-to-kobo/naira-to-kobo.pipe';
import { OrderModeDisplayPipe } from './pipes/order-mode-display/order-mode-display.pipe';
import { OrderModeColorPipe } from './pipes/order-mode-color/order-mode-color.pipe';

@NgModule({
  declarations: [
    OrderColorPipe,
    OrderStatusDisplayPipe,
    CardLoadingComponent,
    AmountComponent,
    StartEntryPipe,
    EndEntryPipe,
    PaginationComponent,
    KoboToNairaPipe,
    NairaToKoboPipe,
    OrderModeDisplayPipe,
    OrderModeColorPipe
  ],
  imports: [
    CommonModule,
    ToastModule,
    PasswordModule,
    InputTextModule,
    DropdownModule
  ],
  exports: [
    /* Components */
    CardLoadingComponent,
    AmountComponent,
    PaginationComponent,

    /* Pipes */
    OrderColorPipe,
    OrderStatusDisplayPipe,
    StartEntryPipe,
    EndEntryPipe,
    OrderModeDisplayPipe,
    OrderModeColorPipe,

    /* Modules */
    FormsModule,
    ReactiveFormsModule,
    ToastModule,
    PasswordModule,
    InputTextModule,
    DirectivesModule,
    DropdownModule,
    DialogModule,
    MultiSelectModule
  ],
})
export class SharedModule { }
