import { Restaurant } from 'src/app/models/restaurant';

export class SaveUser {
    static readonly type = '[UserState] SaveUser';

    constructor(public user: Restaurant) { }
}

export class ClearUser {
    static readonly type = '[UserState] ClearUser';
}
